/* eslint-disable react-hooks/exhaustive-deps */
import { useState, ChangeEvent, MouseEvent, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography, Grid, FormGroup, FormControlLabel } from '@mui/material';
import { TransactionsGiftsHeaders } from 'constants/transactions-table-head-columns';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import CssTable from 'components/CssTable';
import ScreenDialog from 'components/ScreenDialog';
import CssAccordion from 'components/CssAccordion';
import CssCheckbox from 'components/CssCheckbox';
import CssButton from 'components/CssButton';
import DateTimeFilters from 'components/DateTimeFilters';
import FilterButton from 'components/FilterButton';
import CheckTick from 'components/icons/CheckTick';
import { Order, setAccordionExpandByFilters, checkPermissions, getQueryMap } from 'utils';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import useStorage from 'hooks/storage-hook';
import { GlobalContext } from 'context';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: 5,
}));

const Referrals = (props: any) => {
  const { from } = props;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { giftsStatusFilters, setGiftsStatusFilters, paymentFilterDates, setPaymentFilterDates, isFilterCleared, setIsFilterCleared, searchQuery, setSearchQuery, isSearchClosed, setIsSearchClosed, setEnableSearchField, isUpdatePage, setIsUpdatePage, setIndeterminateCheck, setCheckboxList, setSelectedAllChecks, setTransactionsDownloadData, isFilterApplied, setIsFilterApplied, setIsFilterIconDisplay, setEnableSelectAllCheckbox, checkedTxnIds, setCheckedTxnIds, setIsSelectAllCheckboxClicked, setCheckedTransactionStatus, userPermissions, setTransactionsDownloadParams, transactionFilterQuery, setTransactionFilterQuery } = useContext(GlobalContext);
  const { updateGiftsStatusApi, getGiftsApi, getFinOpsGiftsApi, getGiftsByClientApi, getGiftsDownloadApi } = useRequests();
  const updateGiftsStatus = useApi(updateGiftsStatusApi);
  const getGifts = useApi(getGiftsApi);
  const getFinOpsGifts = useApi(getFinOpsGiftsApi);
  const getGiftsByClient = useApi(getGiftsByClientApi);
  const getGiftsDownload = useApi(getGiftsDownloadApi);
  const [clientId, setClientId] = useStorage<number | null>('clientId', null);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openFiltersScreen, setOpenFiltersScreen] = useState(false);
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [enableButtons, setEnableButtons] = useState(true);
  const [openTransactionStatusScreen, setOpenTransactionStatusScreen] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [isEnableCheckbox, setIsEnableCheckbox] = useState(false);
  const [isDateFilterApplied, setIsDateFilterApplied] = useState<boolean>(false);
  const [isUpdatedStatus, setIsUpdatedStatus] = useState<boolean>(false);
  const [activeTransactionStatus, setActiveTransactionStatus] = useState<any>('APPROVED');
  const [batchLength, setBatchLength] = useState<number>(0);
  const [selectedRecordsLength, setSelectedRecordsLength] = useState<number>(0);
  const [batchIds, setBatchIds] = useState<string>('');
  const [rewardType, setRewardType] = useState<string>('');
  const [enableUpdatedConfirmButton, setEnableUpdatedConfirmButton] = useState<boolean>(false);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilters = () => {
    setOpenFiltersScreen(true);
  };

  const onFiltersScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenFiltersScreen(false);
  };

  const onTransactionStatusScreenOpen = () => {
    setOpenTransactionStatusScreen(true);
  };

  const onTransactionStatusScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenTransactionStatusScreen(false);
  };

  const onClearFilters = () => {
    setPaymentFilterDates({ fromDate: '', toDate: '', statusUpdatedFromDate: '',
    statusUpdatedToDate: '' });
    setIsFiltersCleared(true);
    setTransactionFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    enqueueSnackbar('All filters have been cleared successfully.', {
      variant: 'defaultAlert'
    });
    setGiftsStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setIsFilterCleared(true);
    setCheckedTxnIds([]);
    setIsSelectAllCheckboxClicked(false);
    setCheckedTransactionStatus('');
    setEnableSelectAllCheckbox(false);
  };

  const onApply = () => {
    let query = '';
    setOpenFiltersScreen(false);
    setEnableButtons(true);
    setIsFilterApplied(true);
    setIsFilterIconDisplay(true);
    setCheckedTxnIds([]);
    setSelectedAllChecks(false);
    setIndeterminateCheck(false);
    setIsSelectAllCheckboxClicked(false);
    setCheckedTransactionStatus('');
    setTransactionsDownloadData(null);
    setTransactionsDownloadParams(null);

    _.map(giftsStatusFilters, (o: any) => {
      _.map(o.filters, (f: any) => {
        if (f.isChecked) {
          query += `&${o.parentKey}=${f.key}`;
        }
      });
    });

    if (query) {
      setTransactionFilterQuery(query);
    }

    if (paymentFilterDates && (paymentFilterDates.fromDate && paymentFilterDates.toDate) || (paymentFilterDates.statusUpdatedFromDate && paymentFilterDates.statusUpdatedToDate)) {
      setIsDateFilterApplied(true);
    }
    setIsUpdatePage(true);
  };

  const onCancel = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
  };

  const onCheckboxClick = (event: ChangeEvent<HTMLInputElement>, key: string, index: number) => {
    const selectedFilters = _.find(giftsStatusFilters, (o) => o.parentKey === key).filters;
    const newFilters = selectedFilters.map((item: any) => {
      if (item.key === event.target.value) {
        item.isChecked = event.target.checked;
      }

      return item;
    });

    setGiftsStatusFilters((csf: any) => _.map(csf, (c) => {
      if (c.parentKey === key) {
        c.filters = newFilters;
      }
      return c;
    }));
  };

  const onWithdrawalUpdate = (record: any) => {
    const onlyChecked = _.compact(_.map(data, (cl: any) => { 
      if (cl.isChecked) {
        return cl.rewardId;
      }
    }));
    setTransactionStatus(record.status);

    const ids = _.uniq(_.compact(_.map(data, (d) => { 
      if (onlyChecked.includes(d.rewardId)) {
        return d.rewardId;
      }
    })));
    const bl = (_.compact(_.map(data, (d) => {
      if(d.status === 'PENDING') {
        return d;
      }
    }))).length;

    if (onlyChecked.length) {
      setEnableUpdatedConfirmButton(false);
    } else {
      setEnableUpdatedConfirmButton(true);
    }
    setSelectedRecordsLength(onlyChecked.length);
    setBatchLength(bl);
    setBatchIds(ids.join(', '));
    onTransactionStatusScreenOpen();
  };

  const onFilterButton = (e: MouseEvent<HTMLDivElement>) => {
    const filter = (e.target as HTMLDivElement).dataset.filter;
    setActiveTransactionStatus(filter);
  };

  const onStatusUpdateConfirm = () => {
    let params: any = {
      status: activeTransactionStatus,
      currentStatus: transactionStatus,
      rewardType
    }

    if (checkedTxnIds.length) {
      const rewardIdsArray = _.map(checkedTxnIds, (u) => parseInt(u.rewardId));
      params.rewardIds = rewardIdsArray;
    }

    updateGiftsStatus.request(params).then((res) => {
      if (res.status === 200) {
        setIsUpdatedStatus(true);
        setOpenTransactionStatusScreen(false);
        setCheckedTxnIds([]);
        setRewardType('');
        setSelectedAllChecks(false);
        setIndeterminateCheck(false);
      }
    });
  };

  const onTransactionCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    let status = '';
    let reason = '';
    let checkboxListItems = _.map(data, (item: any) => {
      if (item.rewardId === event.target.id) {
        if (!item.isChecked) {
          item.isChecked = true;
          setCheckedTxnIds((ctis: any[]) => ctis.concat({'rewardId': item.rewardId}));
        } else {
          item.isChecked = false;
          setCheckedTxnIds((ctis: any[]) =>_.filter(ctis, (c) => c.rewardId !== event.target.id));
        }
        status = item.status;
        reason = item.rewardType;

        return item;
      }

      if (item.isDisabled) {
        item.isDisabled = false;
      }

      return item;
    });

    setCheckedTransactionStatus(status);
    setRewardType(reason);

    const onlyChecked = _.filter(data, (cl: any) => cl.isChecked);
    if (onlyChecked.length && (data.length > onlyChecked.length)) {
      setIndeterminateCheck(true);
      setSelectedAllChecks(false);
    } else if (onlyChecked.length && (data.length === onlyChecked.length)) {
      setIndeterminateCheck(false);
      setSelectedAllChecks(true);
    } else {
      setIndeterminateCheck(false);
      setSelectedAllChecks(false);
    }
    setData(checkboxListItems);
  };

  const onTransactionsSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let count = 0;
      let reason = '';
      const rewardIds:any = [];
      setData(_.map(data, (item: any, index: number) => {
        if (count < 10 && item.status === 'PENDING') {
          if (data[0].rewardType === 'PROMOTIONAL' && item.rewardType === 'PROMOTIONAL') {
            item.isChecked = true;
            rewardIds.push({'rewardId': item.rewardId});
            reason = item.rewardType;
          } else if (data[0].rewardType === 'REFERRAL' && item.rewardType === 'REFERRAL') {
            item.isChecked = true;
            rewardIds.push({'rewardId': item.rewardId});
            reason = item.rewardType;
          }
        } else {
          item.isDisabled = true;
        }
        if (item.status === 'PENDING') {
          count++;
        }
        return item;
      }));
      setCheckedTxnIds(rewardIds);
      setRewardType(reason);
      setSelectedAllChecks(true);
      setIsSelectAllCheckboxClicked(true);
    } else {
      setData(_.map(data, (item: any) => {
        item.isChecked = false;
        item.isDisabled = false;
        return item;
      }));
      setCheckedTxnIds([]);
      setRewardType('');
      setSelectedAllChecks(false);
      setIsSelectAllCheckboxClicked(false);
    }
    setIndeterminateCheck(false);
  };

  type DownloadParams = {
    q?: string,
    fromDate?: string,
    toDate?: string,
    statusUpdatedFromDate? : string,
    statusUpdatedToDate? : string,
    filterQuery?: string
  }

  const getGiftsDownloadCall = (params: DownloadParams) => {
    const { q, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, filterQuery } = params;

    const data = params;
    delete data.filterQuery;
    if (filterQuery) {
      const filters = _.omitBy(getQueryMap(filterQuery), _.isNil);
      setTransactionsDownloadParams({...data, ...filters});
    } else {
      setTransactionsDownloadParams(data);
    }
    
    getGiftsDownload.request(q, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, filterQuery).then((res) => {
      if (res.status === 200) {
        setTransactionsDownloadData(res.data);
      }
    });
  };

  const handleResponse = (res: any) => {
    if (res.status === 200) {
      const { totalcount } = res.headers;
      setTotalCount(parseInt(totalcount));
      setIsEnableCheckbox(_.findIndex(res.data, (s: any) => (s.status === 'PENDING')) !== -1);
      setEnableSelectAllCheckbox(_.uniq(_.compact((_.map(res.data, (s: any) => { 
        if (s.status === 'PENDING') {
          return s.status;
        }
      })))).length === 1);

      const checkboxListItems = _.filter(res.data, (item: any) => {
        if (item.status === 'PENDING') {
          return item;
        }
      });

      setCheckboxList(checkboxListItems);

      setData(res.data);
      setIsDateFilterApplied(false);

      if (isFilterApplied) {
        enqueueSnackbar('Filters have been applied successfully.', {
          variant: 'successWithUndo',
          onUndo: onClearFilters
        });
        setIsFilterApplied(false);
      }
    }
    if (isUpdatePage) setIsUpdatePage(false);
    if (isUpdatedStatus) setIsUpdatedStatus(false);
    if (isFilterCleared) setIsFilterCleared(false);
    if (isFiltersCleared) setIsFiltersCleared(false);
  };

  const handleResponseOnSearch = (res: any) => {
    if (res.status === 200) {
      const { totalcount } = res.headers;
      setTotalCount(parseInt(totalcount));
      setData(res.data);
      setIsDateFilterApplied(false);

      if (isFilterApplied) {
        enqueueSnackbar('Filters have been applied successfully.', {
          variant: 'successWithUndo',
          onUndo: onClearFilters
        });
        setIsFilterApplied(false);
      }
    }
    if (isUpdatePage) setIsUpdatePage(false);
    if (isUpdatedStatus) setIsUpdatedStatus(false);
    if (isFilterCleared) setIsFilterCleared(false);
    if (isFiltersCleared) setIsFiltersCleared(false);
  };

  const onLinkClick = (data: any) => {
    setClientId(data.customerId);
    setPaymentFilterDates({ fromDate: '', toDate: '', statusUpdatedFromDate: '',
    statusUpdatedToDate: '' });
    setIsFiltersCleared(true);
    setTransactionFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);

    setGiftsStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setIsFilterCleared(true);
    setCheckedTxnIds([]);
    setIsSelectAllCheckboxClicked(false);
    setCheckedTransactionStatus('');
    setEnableSelectAllCheckbox(false);
    setSearchQuery('');
    setIsSearchClosed(false);
    setEnableSearchField(false);
    navigate('/profile', { state: {...data, from: 'TRANSACTIONS'} });
  };

  const checkTransactionPermissions = () => {
    const resourceName = 'PAYMENTS';

    return checkPermissions(userPermissions, resourceName);
  };
  
  const convertUtc = (value: string) => {
    return moment(value, 'YYYY-MM-DD h:m:s A').utc(false).format('YYYY-MM-DD HH:mm:ss');
  };

  useEffect(() => {
    const newFilters = _.map(giftsStatusFilters, o => {
      if (_.findIndex(o.filters, (f: any) => f.isChecked) !== -1) {
        return true;
      }
      return false;
    });
    const isEnabled = _.compact(newFilters)[0];

    if (isFiltersCleared && !isEnabled) {
      setEnableButtons(true);
    } else if (isEnabled === true) {
      setEnableButtons(false);
    } else if (paymentFilterDates && (paymentFilterDates.fromDate && paymentFilterDates.toDate) || (paymentFilterDates.statusUpdatedFromDate && paymentFilterDates.statusUpdatedToDate)) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  }, [giftsStatusFilters, isFiltersCleared, paymentFilterDates]);

  useEffect(() => {
    const fromDate = paymentFilterDates.fromDate && paymentFilterDates['fromDateTime'] ? convertUtc(`${paymentFilterDates.fromDate} ${paymentFilterDates['fromDateTime']}`) : (paymentFilterDates.fromDate && !paymentFilterDates['fromDateTime'] ? `${paymentFilterDates.fromDate}` : '');

    const toDate = paymentFilterDates.toDate && paymentFilterDates['toDateTime'] ? convertUtc(`${paymentFilterDates.toDate} ${paymentFilterDates['toDateTime']}`) : (paymentFilterDates.toDate && !paymentFilterDates['toDateTime'] ? `${paymentFilterDates.toDate}` : '');

    const statusUpdatedFromDate = paymentFilterDates['statusUpdatedFromDate'] && paymentFilterDates['statusUpdatedFromDateTime'] ? convertUtc(`${paymentFilterDates['statusUpdatedFromDate']} ${paymentFilterDates['statusUpdatedFromDateTime']}`) : (paymentFilterDates['statusUpdatedFromDate'] && !paymentFilterDates['statusUpdatedFromDateTime'] ? `${paymentFilterDates['statusUpdatedFromDate']}` : '');

    const statusUpdatedToDate = paymentFilterDates['statusUpdatedToDate'] && paymentFilterDates['statusUpdatedToDateTime'] ? convertUtc(`${paymentFilterDates['statusUpdatedToDate']} ${paymentFilterDates['statusUpdatedToDateTime']}`) : (paymentFilterDates['statusUpdatedToDate'] && !paymentFilterDates['statusUpdatedToDateTime'] ? `${paymentFilterDates['statusUpdatedToDate']}` : '');

    if (!searchQuery) {
      setPage(isUpdatePage ? 0 : page);

      if (from === 'FINOPS') {
        getFinOpsGifts.request(page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, transactionFilterQuery).then((res) => {
          handleResponse(res);
        });
      } else if (from === 'TRANSFERS') {
        getGiftsByClient.request(clientId, page + 1, rowsPerPage).then((res) => {
          handleResponse(res);
        });
      } else {
        getGifts.request(page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, transactionFilterQuery).then((res) => {
          handleResponse(res);
        });
        getGiftsDownloadCall({ q: searchQuery, fromDate: fromDate, toDate: toDate, statusUpdatedFromDate: statusUpdatedFromDate, statusUpdatedToDate: statusUpdatedToDate, filterQuery: transactionFilterQuery });
      }
    } else if (searchQuery !== '' && !isSearchClosed) {
      setPage(isUpdatePage ? 0 : page);
      if (from === 'FINOPS') {
        getFinOpsGifts.request(page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, transactionFilterQuery).then((res) => {
          handleResponse(res);
        });
      } else if (from === 'TRANSFERS') {
        getGiftsByClient.request(page + 1, rowsPerPage).then((res) => {
          handleResponse(res);
        });
      } else {
        getGifts.request(page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, transactionFilterQuery, searchQuery).then((res) => {
          handleResponseOnSearch(res);
        });
        getGiftsDownloadCall({ q: searchQuery, fromDate: fromDate, toDate: toDate, statusUpdatedFromDate: statusUpdatedFromDate, statusUpdatedToDate: statusUpdatedToDate, filterQuery: transactionFilterQuery });
      }
    }
  }, [page, rowsPerPage, order, orderBy, searchQuery, isSearchClosed, isFilterCleared, transactionFilterQuery, isFiltersCleared, isUpdatePage, isDateFilterApplied, isUpdatedStatus]);

  useEffect(() => {
    if (transactionStatus === 'PENDING') {
      setActiveTransactionStatus('APPROVED');
    }
  }, [transactionStatus]);

  useEffect(() => {
    if (checkedTxnIds.length && checkedTxnIds.length <= 10) {
      setData((rd: any) => _.map(rd, (d) => {
        if (!_.some(checkedTxnIds, {['rewardId']: d.rewardId}) && rewardType !== d.rewardType) {
          d.isDisabled = true;
        } else {
          d.isDisabled = false;
        }
        return d;
      }))
    }
  }, [checkedTxnIds, rewardType]);

  return (
    <>
      <CssTable
        headers={TransactionsGiftsHeaders}
        totalCount={totalCount}
        data={data}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        onRequestSort={handleRequestSort}
        onTablePageChange={handleChangePage}
        onTableRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: {md: 3, xs: 0} }}
        onFilters={handleFilters}
        from={from}
        {...(from !== 'TRANSFERS' && checkTransactionPermissions() && {hasCheckbox: isEnableCheckbox, onStatusAction: onWithdrawalUpdate, onRowCheckbox: onTransactionCheckbox, onSelectAll: onTransactionsSelectAll, rowClassName: 'no-row-click'})}
        {...(from !== 'TRANSFERS' && {isDesktopFilters: true, highlightText: searchQuery, onLinkClick: onLinkClick})}
      />
      <ScreenDialog
        title={'Filters'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openFiltersScreen}
        onScreenClose={onFiltersScreenClose}
      >
        <StyledBox>
          <Typography variant='body2' sx={{fontWeight: 500}}>Filters</Typography>
          <Typography variant='body2' sx={{cursor: 'pointer'}} onClick={onClearFilters}>&mdash; Clear filters</Typography>
        </StyledBox>
        <Box sx={{m: 4, mt: 0}}>
          {from === 'REFERRAL_GIFTS' && giftsStatusFilters && giftsStatusFilters.map((item: any, index: number) => (
            <CssAccordion title={item.title} key={index} isExpand={setAccordionExpandByFilters(item.filters)}>
              <FormGroup>
                {item.filters.map((filter: any, i: number) => {
                  return (
                    <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                  )
                })}
              </FormGroup>
            </CssAccordion>
          ))}

          {from === 'FINOPS' && giftsStatusFilters && giftsStatusFilters.map((item: any, index: number) => { 
            if (item.parentKey === 'rewardType') {
              return (
                <CssAccordion title={item.title} key={index} isExpand={setAccordionExpandByFilters(item.filters)}>
                  <FormGroup>
                    {item.filters.map((filter: any, i: number) => {
                      return (
                        <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                      )
                    })}
                  </FormGroup>
                </CssAccordion>
              )
            }
          })}

          <CssAccordion title='Created date' isExpand={paymentFilterDates && paymentFilterDates.fromDate && paymentFilterDates.toDate}>
            <DateTimeFilters fromLabel={'From'} toLabel={'To'} />
          </CssAccordion>
          <CssAccordion title='Status updated' isExpand={paymentFilterDates && paymentFilterDates.statusUpdatedFromDate && paymentFilterDates.statusUpdatedToDate}>
            <DateTimeFilters fromLabel={'From'} toLabel={'To'} id='statusUpdated' />
          </CssAccordion>

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5 }}>
              <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={onCancel} disabled={enableButtons}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={onApply} disabled={enableButtons}>Apply</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>

      <ScreenDialog
        title={'Gifts request status'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openTransactionStatusScreen}
        onScreenClose={onTransactionStatusScreenClose}
      >
        <StyledBox>
          <Typography variant='body2' sx={{fontWeight: 500}}>Gifts request status</Typography>
        </StyledBox>

        <Box sx={{pl: 4, pr: 4}}>
          {transactionStatus === 'PENDING' && <>
            <FilterButton className={activeTransactionStatus === 'REJECTED' ? 'active btn-filter btn-flat custom btn-transaction-status' : 'btn-filter btn-flat custom btn-transaction-status'} sx={{pt: 1, pb: 1}} filter='REJECTED' onClick={onFilterButton}>Rejected <CheckTick /></FilterButton>
            <FilterButton className={activeTransactionStatus === 'APPROVED' ? 'active btn-filter btn-flat custom btn-transaction-status' : 'btn-filter btn-flat custom btn-transaction-status'} filter='APPROVED' onClick={onFilterButton}>Approved <CheckTick /></FilterButton>
          </>}

          {batchIds && <Typography variant='body2' sx={{mt: 2.5}}><b>{selectedRecordsLength}</b> reward {selectedRecordsLength === 1 ? 'is': 'are'} selected.</Typography>}

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5, justifyContent: 'space-between', display: 'flex' }}>
              <CssButton variant='outlined' sx={{width: '35%', mr: 1, ml: 0}} onClick={onTransactionStatusScreenClose}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '35%', mr: 0, ml: 1}} onClick={onStatusUpdateConfirm} className='active' disabled={enableUpdatedConfirmButton}>Confirm</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>
    </>
  );
};

export default Referrals;